import api from './api'

export default {
  criar(body){
    const config = {
      headers: { 'x-access-token': `${localStorage.getItem('tktaskcheck')}`}
    };
    return api().post('/atividade/upinsert', body, config)
  },
  registrarMovimento(body){
    const config = {
      headers: { 'x-access-token': `${localStorage.getItem('tktaskcheck')}`}
    };
    return api().post('/atividade/registrarMovimento', body, config)
  },
  registrarMovimentoPorCliente(body){
    const config = {
      headers: { 'x-access-token': `${localStorage.getItem('tktaskcheck')}`}
    };
    return api().post('/atividade/registrarMovimentoPorCliente', body, config)
  },
  atribuirUsuario(body){
    const config = {
      headers: { 'x-access-token': `${localStorage.getItem('tktaskcheck')}`}
    };
    return api().post('/atividade/atribuirUsuario', body, config)
  },
  removerUsuario(body){
    const config = {
      headers: { 'x-access-token': `${localStorage.getItem('tktaskcheck')}`}
    };
    return api().post('/atividade/removerUsuario', body, config)
  },
  marcarConcluida(body){
    const config = {
      headers: { 'x-access-token': `${localStorage.getItem('tktaskcheck')}`}
    };
    return api().post('/atividade/marcarConcluida', body, config)
  },
  atualizar(body){
    const config = {
      headers: { 'x-access-token': `${localStorage.getItem('tktaskcheck')}`}
    };
    return api().post('/atividade/upinsert', body, config)
  },
  registrarObsevacao(body){
    const config = {
      headers: { 'x-access-token': `${localStorage.getItem('tktaskcheck')}`}
    };
    return api().post('/atividade/registrarObsevacao', body, config)
  },
  buscar(body){
    const config = {
      headers: { 'x-access-token': `${localStorage.getItem('tktaskcheck')}` }
    };
    return api().post('/atividade/select', body, config)
  },
  buscarMovimento(body){
    const config = {
      headers: { 'x-access-token': `${localStorage.getItem('tktaskcheck')}` }
    };
    return api().post('/atividade/buscarMovimento', body, config)
  },
  buscarMovimentoPorCliente(body){
    const config = {
      headers: { 'x-access-token': `${localStorage.getItem('tktaskcheck')}` }
    };
    return api().post('/atividade/buscarMovimentoPorCliente', body, config)
  },
  buscarAtividadesAtribuidas(body){
    const config = {
      headers: { 'x-access-token': `${localStorage.getItem('tktaskcheck')}` }
    };
    return api().post('/atividade/buscarAtividadesAtribuidas', body, config)
  },
  enviarNotificacao(body){
    const config = {
      headers: { 'x-access-token': `${localStorage.getItem('tktaskcheck')}` }
    };
    return api().post('/atividade/notificarUsuarios', body, config)
  },
  requisitarObservacoes(body){
    const config = {
      headers: { 'x-access-token': `${localStorage.getItem('tktaskcheck')}` }
    };
    return api().post('/atividade/requisitarObservacoes', body, config)
  },

  buscarAtividadesParaExportar(body){
    const config = {
      headers: { 'x-access-token': `${localStorage.getItem('tktaskcheck')}` }
    };
    return api().post('/atividade/buscarAtividadeExportar', body, config)
  },

  buscarAtividadesSecundarias(body){
    const config = {
      headers: { 'x-access-token': `${localStorage.getItem('tktaskcheck')}` }
    };
    return api().post('/horastrabalhadas/atividadesSecundarias/get', body, config)
  },

  addAtividadesSecundarias(body){
    const config = {
      headers: { 'x-access-token': `${localStorage.getItem('tktaskcheck')}` }
    };
    return api().post('/horastrabalhadas/atividadesSecundarias', body, config)
  }
}