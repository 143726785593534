import api from './api'

export default {
    criarPrioridade(body) {
        const config = {
            headers: { 'x-access-token': `${localStorage.getItem('tktaskcheck')}`}
          };
        return api().post('/prioridades/cadastrar', body, config);
    },
    buscarPrioridade(body) {
        const config = {
            headers: { 'x-access-token': `${localStorage.getItem('tktaskcheck')}`}
          };
        return api().post('/prioridades/select', body, config)
    },
    atualizarPrioridade(body){
      const { id } = body;
      const config = {
        headers: { 'x-access-token': `${localStorage.getItem('tktaskcheck')}`}
      };
    return api().put(`/prioridades/atualizar/${id}`, body, config)
},

    atualizarStatusPrioridade(body){
      const { id } = body;
      const config = {
        headers: { 'x-access-token': `${localStorage.getItem('tktaskcheck')}`}
      };
    return api().put(`/prioridades/AtualizarStatus/${id}`, body, config)
    },

    buscarProjetosExcel(body){
      const config = {
        headers: { 'x-access-token': `${localStorage.getItem('tktaskcheck')}`}
      };
    return api().post(`/prioridades/selectPrioridadesExcel/`, body, config)
    },

    buscarProjetos(body){
      
      const config = {
        headers: { 'x-access-token': `${localStorage.getItem('tktaskcheck')}`}
      };
      return api().post(`/prioridades/buscarProjetos/`, body, config)

    },
    buscarMovimentoProjetos(body){
      
      const config = {
        headers: { 'x-access-token': `${localStorage.getItem('tktaskcheck')}`}
      };
      return api().post(`/prioridades/buscarMovimentoProjetos/`, body, config)

    },
    salvarNovaMovimentacao(body){
      
      const config = {
        headers: { 'x-access-token': `${localStorage.getItem('tktaskcheck')}`}
      };
      return api().post(`/prioridades/salvarNovaMovimentacao/`, body, config)

    },

    buscarAtividadesProjetos(body){
      
      const config = {
        headers: { 'x-access-token': `${localStorage.getItem('tktaskcheck')}`}
      };
      return api().post(`/prioridades/buscarAtividadesProjetos/`, body, config)

    },

    

}